body {
  font-family: sans-serif;
}

.list li {
  margin: 0.75rem 0;
}

.info {
  color: rgb(0, 49, 0);
  background-color: rgb(230, 246, 230);
  padding: 1rem;
  border-radius: 4px;
}

.accordion {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
}

.item {
  border-bottom: 1px solid #ccc;
}

.itemBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 1rem;
  font-size: 1rem;
  text-align: left;
  color: #007bff;
  background-color: transparent;
  border: none;
}

.itemBtn:hover {
  background-color: #f3f3f3;
}

.itemBtnExpanded {
  background-color: #e7e7e7;
}

.itemContent {
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
  padding: 1rem;
}

.chevron {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}
