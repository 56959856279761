.project-box{
  background-color: var(--white);
  margin: 20px 10px;
  flex: 1;
  transition: transform 1s, box-shadow 0.5s;
  border-radius: 10px;
  box-shadow: 0px 0px 20px var(--shadow-color);
  cursor: pointer;
}

.project-image{
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.project-box-content{
  padding: 15px 30px 25px 20px;
}

.project-box p{
  margin: 0px;
  padding-top: 8px;
}

.project-box:hover{
  transform: scale(1.01);
  box-shadow: 0px 0px 20px var(--shadow-color-dark);
  transition: transform 0.5s, box-shadow 0.5s;
}

.no-hover > .project-box:hover{
  transform: scale(1);
}

@media only screen and (max-width: 767px) {
  .project-box{
    margin: 20px 5px;
  }
}