.navbar{
  position: fixed !important;
  z-index: 206;
  width: 100vw;
  background-color: var(--navbar-background-color);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  filter: drop-shadow(0px 0.2vw 0.4vw #00000009);
  backdrop-filter: blur(12px);
  border-bottom-style: solid;
  border-color: transparent;
  border-bottom-color: var(--light-dark-accent-color);
  border-bottom-width: 1px;
}

.navbar-flex{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 2px;
}

.navbar-link-text{
  text-decoration: none;
  color: var(--font-color);
  padding: 18px 22px;
  margin: -2px 2px;
  font-size: 15px;
  background: 
    linear-gradient(to right, #00000000, #00000000),
    linear-gradient(to right, var(--accent-color), var(--accent-color));
  background-size: 0 3px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  font-family: "Helvetica", "Arial", sans-serif;
}

.navbar-link-text:hover{
  background-size: 100% 3px, 100% 3px;
  transition: background-size 400ms;
}

.navbar-link-text-selected{
  font-weight: bold;
  background-size: 100% 3px, 100% 3px;
  transition: background-size 400ms;
}

.navbar-items-mobile{
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: -1;
  box-shadow: 0px -2px 20px #0000004b;
  transition: transform 500ms, box-shadow 500ms;
}

.navbar-items-mobile-open{
  transform: translateY(-100%);
  box-shadow: 0px -2px 20px #00000000;
  transition: transform 500ms, box-shadow 3500ms;
}

.navbar-link-text-mobile{
  text-decoration: none;
  color: var(--font-color);
  padding: 18px 30px;
  margin: -1px -5px;
  font-size: 18px;
  width:100vw;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: -2;
  transition: background-color 700ms;
  font-family: "Helvetica", "Arial", sans-serif;
}

.navbar-link-text-mobile:hover{
  background-color: var(--accent-color);
  transition: background-color 200ms;
}

.navbar-link-text-selected-mobile{
  font-weight: bold;
  background-color: var(--accent-color);
  transition: background-color 200ms;
}

.navbar-menu-icon{
  height:25px;
  padding:10px;
  /*position:absolute;*/
  right:15px;
  border-radius: 10px;
  transition: background-color 500ms ;
  cursor: pointer;
}
.navbar-menu-icon:hover{
  background-color: var(--light-dark-accent-color);
  transition: background-color 500ms ;
}

.navbar-socials{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  flex-wrap: wrap;
  margin-left: 10px;
}

.navbar-social{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
}

.navbar-social-image{
  width:25px;
  height:25px;
  object-fit: contain;
  transition: transform 500ms;
  opacity: 0.7;
}

.navbar-social:hover > .navbar-social-image{
  transform: scale(1.1);
  transition: transform 200ms;
}

@media only screen and (max-width: 1250px) {
  .navbar-link-text{
    padding: 18px 15px;
    margin: -2px 2px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1100px) {
  .navbar-link-text{
    padding: 18px 4px;
    margin: -2px 10px;
    font-size: 13px;
  }
  .navbar-social{
    padding: 10px 5px;
  }
  .navbar-social-image{
    width:20px;
    height:20px;
  }
}

@media only screen and (max-width: 900px) {
  .navbar-link-text{
    padding: 18px 3px;
    margin: -2px 5px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 820px) {
  .navbar-link-text{
    padding: 18px 3px;
    margin: -2px 2px;
    font-size: 13px;
  }
  .navbar-social{
    padding: 10px 4px;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-social{
    padding: 10px 6px;
  }
  .navbar-social-image{
    width:20px;
    height:20px;
  }
  .navbar-socials{
    margin-right: 25px;
    margin-top: -2px;
  }
}

@media only screen and (max-width: 460px) {
  .navbar-social{
    padding: 10px 2px;
  }
  .navbar-social-image{
    width:20px;
    height:20px;
  }
  .navbar-socials{
    margin-right: 18px;
    margin-top: -2px;
  }
}

.rvl-icon-desktop{
  height:40px;
  padding:2px;
  padding-left:0px;
  margin-right:20px;
  image-rendering: auto;
}

.rvl-icon-mobile{
  height:40px;
  padding:5px;
  margin-right:20px;
  position:absolute;
  left:5px;
  top:4px;
  image-rendering: -webkit-optimize-contrast;
}
