.lab-intro-title{
  margin: 10px;
}

.lab-intro-box-container{
  display: flex;
  flex-wrap: wrap;
  text-overflow: clip;
}

.lab-intro-box{
  background-color: var(--white);
  padding: 5px 5px;
  margin: 5px 0px;
  margin-right: 10px;
  min-width: 100px;
  max-width: 250px;
  flex: 1;
  transition: transform 1s, box-shadow 0.5s;
  border-radius: 10px;
  box-shadow: 0px 0px 20px var(--shadow-color);
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-overflow: clip;
}

.lab-intro-box-text{
  background-color: var(--white);
  margin-right: 0px;
  margin-top: 1px;
  flex: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-overflow: clip;
}

.lab-intro-box-text h2{
  background-color: var(--white);
  margin-right: 10px;
  margin-bottom: 10px;
  flex: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-overflow: clip;
}

.lab-intro-box h3{
  margin: 0px;
  font-size: 21px;
  margin-bottom: 5px;
}

.lab-intro-box p{
  margin: 0px;
  padding-top: 10px;
  color: var(--font-color-light);
  font-size: 15px;
}

.lab-intro-box:hover{
  transform: scale(1.01);
  box-shadow: 0px 0px 20px var(--shadow-color-dark);
  transition: transform 0.5s, box-shadow 0.5s;
}

 @media only screen and (max-width: 767px) {
/*@media (pointer: coarse) { */

    .lab-intro-box h3{
    font-size: 18px;
  }
  .lab-intro-box{
    min-width: 20%;
    max-width: 40%;
    padding: 10px 10px;
    margin:10px 10px 10px 0px;
    float: left;
  }

  .lab-intro-box-container{
    display: block;  
    flex-wrap: wrap;
    text-overflow: clip;
  }

  .lab-intro-box-text{
      display: block;   
  }

  .research-theme-title{
    margin:0px;
    margin-top: 20px;
  }
}
